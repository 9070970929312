import firebase from "firebase/app";
import firebaseConfig from "./config/firebaseConfig";

import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import './css/core.css'
require('dotenv').config();

firebase.initializeApp(firebaseConfig);


ReactDOM.render(
  <React.StrictMode>
    <Root/>
  </React.StrictMode>,
  document.getElementById('root')
);