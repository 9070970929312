import React, {lazy, Suspense} from 'react'
import {Route, Switch} from "react-router-dom";


let NotFound = lazy(() => import('./components/common/NotFound'))
let Header = lazy(() => import('./components/header/Header'))
let Footer = lazy(() => import('./components/footer/Footer'))
let Home = lazy(() => import('./components/home/Home'))

let Support = lazy(() => import('./components/support/Support'))
let PriceCalculator = lazy(() => import ('./components/priceCalculator/PriceCalculator'))

let Service_inpo = lazy(() => import ('./components/service/Service_inpo'))
let Service_Main = lazy(() => import('./components/service_main/ServiceMain'))
let Service_Guideline = lazy(() => import('./components/service_guideline/Service_guideline'))

let Guideline_zcare = lazy(() => import('./components/service_guideline/subpage/Service_zcare'))
let Guideline_baby_care = lazy(() => import('./components/service_guideline/subpage/Baby_care'))
let Guideline_Support_care = lazy(() => import('./components/service_guideline/subpage/Support_care'))
let Guideline_Notice = lazy(() => import('./components/service_guideline/subpage/Notice'))
let Usagefee = lazy(() => import('./components/service/Usage_fee'))
let Usageinpo = lazy(() => import('./components/service/Usage_inpo'))

let Apply = lazy(() => import('./components/apply/Apply'));
let ApplyFinal = lazy(() => import('./components/apply/ApplyFinal'));
let ApplyResult = lazy(() => import('./components/apply/ApplyResult'));

let EventList = lazy(() => import('./components/siteService/EventDetails/EventList'))
let EventDetails = lazy(() => import('./components/siteService/EventDetails/EventDetails'))
let ReviewDetail = lazy(() => import('./components/siteService/ReviewDetail/ReviewDetail'))

let Dearnews = lazy(() => import('./components/community/Dearnews'))
let Deartoon = lazy(() => import('./components/community/Deartoon'))

let Review = lazy(() => import('./components/review/Review'))
let PriceResult = lazy(() => import('./components/priceCalculator/PriceResult'))

let Newspaper = lazy(() => import('./components/siteService/newspaper/Newspaper'))

let Education = lazy(() => import('./components/service/Education'))
let Branch = lazy(() => import('./components/branch/Branch'))
let Edition = lazy(() => import('./components/edition/Edition'))

// let editionDataFirst = lazy(() => import('./components/edition/edition_data/FirstData'))
// let editionDataSecound = lazy(() => import('./components/edition/edition_data/SecoundData'))
// let editionDataThird = lazy(() => import('./components/edition/edition_data/ThirdData'))
// let editionDataFourth = lazy(() => import('./components/edition/edition_data/FourthData'))
//핵심적인 라우트 설정과 페이지 규격 설정

let EditionDetail = lazy(() => import('./components/siteService/editionDetail/EditionDetail'))

const App = () => {
  return (
    <div className="app">
      <Suspense fallback={<div></div>}>
        <Header/>
        <div className="main">
          <Switch>
            <Route path="/" component={Home} exact/>
            <Route path="/support" component={Support} exact/>
            <Route path="/calculator" component={PriceCalculator} exact/>
            <Route path="/calculator/result" component={PriceResult} exact/>
            {/*▼서비스 소개 메인*/}
            <Route path="/service" component={Service_Main} exact/>
            {/*▼서비스 소개*/}
            <Route path="/service/inpo" component={Service_inpo} exact/>
            {/*▼산후관리사 역할 - 메인*/}
            <Route path="/guideline" component={Service_Guideline} exact/>
            {/*▼산후관리사 역할 - 산모관리*/}
            <Route path="/guideline/1" component={Guideline_zcare} exact/>
            {/*▼산후관리사 역할 - 신생아관리*/}
            <Route path="/guideline/2" component={Guideline_baby_care} exact/>
            {/*▼산후관리사 역할 - 가사지원*/}
            <Route path="/guideline/3" component={Guideline_Support_care} exact/>
            {/*▼산후관리사 역할 - 유의사항*/}
            <Route path="/guideline/4" component={Guideline_Notice} exact/>
            {/*▼이용요금*/}
            <Route path="/usagefee" component={Usagefee} exact/>
            {/*이용절차*/}
            <Route path="/usageinpo" component={Usageinpo} exact/>
            {/*▼이벤트 리스트*/}
            <Route path="/event" component={EventList} exact/>
            {/*▼이벤트 상세*/}
            <Route path="/event/@:id" component={EventDetails} exact/>
            {/*▼후기 상세*/}
            <Route path="/review/@:id" component={ReviewDetail} exact/>
            {/*▼디어뉴스*/}
            <Route path="/dearnews" component={Dearnews} exact/>
            {/*▼디어툰*/}
            <Route path="/deartoon" component={Deartoon} exact/>
            <Route path="/apply" component={Apply} exact/>
            <Route path="/applying" component={ApplyFinal} exact/>
            <Route path="/apply/result" component={ApplyResult} exact/>
            <Route path="/review" component={Review} exact/>
            <Route path="/newspapper" component={Newspaper} exact/>
            {/*▼공지사항*/}
            <Route path="/edition" component={Edition} exact/>
            {/*▼지사 안내*/}
            <Route path="/branch" component={Branch} exact/>
            {/*▼산후도우미 교육*/}
            <Route path="/education" component={Education} exact/>
            <Route path="/edition/@:id" component={EditionDetail} exact/>
            {/* <Route path="/edition/@2" component={editionDataSecound} exact/>
            <Route path="/edition/@3" component={editionDataThird} exact/>
            <Route path="/edition/@4" component={editionDataFourth} exact/> */}
            <Route component={NotFound}/>
          </Switch>
        </div>
        <Footer/>
      </Suspense>
    </div>
  );
};

export default App;
