import React from 'react'
import { BrowserRouter } from "react-router-dom";
import App from './App'

const {Kakao} = window;
//redux, 전체 세션관리등을 할 예정
Kakao.init(process.env.REACT_APP_KAKAO_KEY);

const Root = () => {
    return (
    <BrowserRouter>
        <App/>
    </BrowserRouter>
    )
}

export default Root
